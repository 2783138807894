<template>
    <div class="has_fixed_bottom_box">
        <div class="botC">
            <div class="container pt-3">
                <div class="top_box mb-2 d-flex justify-content-between">
                    <h4>{{item.name}}</h4>
                    <!-- <div @click="bookmark = !bookmark">
                        <i class="far fa-star text-secondary" :class="{'text-warning fas' : bookmark}"></i>
                    </div> -->
                </div>
                <div class='col-12 text-center'>
                    <img :src="item.image" :alt="item.image" width="80%" class='mx-auto'>
                </div>
                <!-- <div class="price_box border bg-light mt-3 p-3 rounded">
                    <small class="text-secondary">Current price</small>
                    <div class="d-flex align-items-end">
                        <h3 class="mb-0">0.1 <span>ETH</span></h3>
                        <small class="text-secondary ml-2">$121.43</small>
                    </div>
                </div> -->
                <div class="desc_box small px-1 py-3">
                    {{item.description}}
                </div>
                <div class="accd">
                    <div class="accd-header border-bottom">
                        <i class="fal fa-info-square mr-2"></i> <b>Details</b></div>
                    <ul class="accd-body">
                        <li class="d-flex justify-content-between">
                            <span>Owner Address</span> <b>{{cutAdr(item.address)}}</b>
                        </li>
                        <li class="d-flex justify-content-between">
                            <span>Token ID</span><b>{{item.token_id}}</b>
                        </li>
                        <li class="d-flex justify-content-between">
                            <span>Chain</span> <b>{{item.coin}}</b>
                        </li>
                    </ul>
                </div>

                <!-- <div class="accd mt-2">
                    <div class="accd-header border-bottom">
                        <i class="fas fa-exchange mr-2"></i> <b>Item Activity</b></div>
                    <div class="w-100">
                        <div class="table-responsive mb-0">
                            <table class="table table-sm small w-100">
                                <thead class="bg-white">
                                    <th>Event</th>
                                    <th>Price</th>
                                    <th>From</th>
                                    <th>Date</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in activity" :key="index">
                                        <td>{{item.event}}</td>
                                        <td>{{item.price}}ETH</td>
                                        <td>{{cutAdr(item.from)}}</td>
                                        <td>{{item.date}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>

        <!-- <div class="fixed_bottom_box p-2 d-flex">
            <button class="btn w-100 btn-primary"><small>Add to Cart</small></button>
            <button class="btn btn-primary ml-1"><i class="fas fa-bolt"></i></button>
        </div> -->
    </div>
</template>

<script>
export default {
    data(){
        return{
            prevP: false,
            title:"HIDDEN #1",
            bookmark: false,
            item: {
                name:"HIDDEN #1",
                description:"Puzzle Fantasy is a global P2E game that has been issued a card that can be used as a game character. PFN holders can access the puzzle fantasy P2E version to mine PF TOKEN, and NFT holders can mine more NFTs depending on their characteristics. Benefits of PFN holders: There are many additional benefits such as partial payment of all profits on the platform, rising P2E mining, additional staking rates, cashing PF tokens, and purchasing holder-only products",
                image:"https://pfpnft.s3.ap-northeast-2.amazonaws.com/hidden/hidden.png",
                no: 1,
                coin: "ETH",
                token_id: 1,
                address: "0x771CDD823ad341eF99850f5c17759bfA0D467238",
                url: "https://pfpnft.s3.ap-northeast-2.amazonaws.com/hidden/1.json",
                attributes:[{trait_type:"Unknown",value:"Unknown"}]
            },
            activity: [
                {
                    event: "취소",
                    price: "0.5",
                    from: "0x32df903mehiehieogjeoeferagrx",
                    date: "2022-12-20 00:00:00",
                },
                {
                    event: "판매등록",
                    price: "0.5",
                    from: "0x32df903mehiehieogjeoeferagrx",
                    date: "2022-12-20 00:00:00",
                },
                {
                    event: "NFT생성",
                    price: "0.5",
                    from: "0x32df903mehiehieogjeoeferagrx",
                    date: "2022-12-20 00:00:00",
                },
            ]
        }
    },
    created() {
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP);
        this.$store.commit("backDashboard",this.pageD);
    },
    methods:{ 
        cutAdr(payload){
            let frontAddress = payload.substr(0,5);
            let EndAddress = payload.substr(-4,4);

            return frontAddress +'...'+ EndAddress
        },
    }
}
</script>

<style scoped>
.accd{
    width: 100%;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgb(229, 232, 235);
    display: flex;
    flex-direction: column;
    font-size: 16px;
}
.accd-header, .accd-body{
    padding: 20px;
    width: 100%;
}
.accd-body span{
    font-weight: light;
    color: #888;
}
</style>