<template>
    <div id="Commision">
        <div class="container p-0">
            <div class="nft_box">
                <ul class="d-flex flex-wrap p-3">
                    <li class="nft_list" v-for="(item,index) in nft" :key="index">
                        <router-link :to="`/${$i18n.locale}/page/nft/${item.no}`">
                            <div class="embed-responsive embed-responsive-1by1">
                                <img :src="item.image" class="w-100 embed-responsive-item"/>
                            </div>
                            <div class="info p-3">
                                <!-- <pre class='pre'>{{item.descript}}</pre> -->
                                <div class="font-weight-bold text-truncate mb-1">{{item.name}}</div>
                                <!-- <div><b>{{item.price?item.price:'N/A'}}</b> {{item.coin}}</div> -->
                            </div>
                        </router-link>
                    </li>
                    <li class="empty_data font-weight-light" v-if="nft.length<1">
                        <i class="far fa-gifts fa-2x d-block mb-3"></i>
                        현재 보유한 NFT가 없어요.
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            // net: this.$route.params.net,
            // title: this.$route.params.idx,
            prevP: false,
            pageD: true,
            assets:0,
            usd:0,
            lock: 0,
            rate: {},
            infoPp: false,
            nft: [],
            title: "NFT"   
        }
    },
    created() {
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP);
        this.$store.commit("backDashboard",this.pageD);
    },
    methods:{          
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '200'){                         
                        this.GET_NFT();
                        // this.CoinRate();
                        // this.GetWalletInfoToken();
                        // this.GetWalletLock();
                    }else if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },        
        GET_NFT(){            
            
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/getNFTList`, {}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.nft = res.data.result;
                        this.usd = (parseFloat(this.assets) * parseFloat(this.rate.usd)).toFixed(6);
                    }
                }
            })
        },     
        GetWalletLock() {
            const symbol = this.title;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/lock/check`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.lock = res.data.lock;
                    }
                }
            })
        },
        TokenHistory(){
            const symbol = this.title;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/transaction/list/each`,{symbol}).then(
            res => {
                if(res.status==200){
                    if(res.data.code=='200'){
                        this.history = res.data.list;
                    }
                }
            })
        },
        CoinRate() {
            const symbol = this.title;
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/rate/each`, {symbol}).then(
            res => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.rate = res.data.rate;
                    }
                }
            })
        },
        usdCalc(value){
            const rate = this.rate;
            return (parseFloat(rate.usd) * parseFloat(value)).toFixed(6);
        },
        CopyWallet: function (addr) {
            this.$copyText(addr).then( 
            () =>{
                this.$fire({title:this.$t('mywallet.18'),html : addr});
            })
        },
        typeName: function(val){
            if(val == 'W') return this.$t("coin.9")
            else if(val == 'D') return this.$t("coin.8")
        },
        statusName: function(val){            
            if(val == null) return this.$t("coin.14")
            else if(val != null) return this.$t("coin.13")
            else return this.$t("coin.15")
        },
        hashCheck: function(hash) {
            const net = this.selected_item.net;
            if(net === 'BTC') window.open('https://www.blockchain.com/btc/tx/'+hash, '_blank');
            else if(net === 'ETH') window.open('https://etherscan.io/tx/'+hash, '_blank');
            else if(net === 'BNB') window.open('https://bscscan.com/tx/'+hash, '_blank');
            else if(net === 'AVAX') window.open('https://snowtrace.io/tx/'+hash, '_blank');
        },
        infoPpOn: function(item){
            this.selected_item = item;
            this.infoPp = true;
        },
        infoPpOff: function(){
            this.infoPp = false;
        },
    }
}
</script>

<style scoped>
.container{
    background-color: transparent !important;
}
.nft_box>ul{
    gap: 2%;
}
.nft_list{
    box-shadow: rgba(0, 0, 0, .12) 0px 4px 8px;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 0;
    overflow: hidden;
    width: 48%;
}
.nft_list img{
    object-fit: cover;
}
.nft_list .info{
    font-size: 14px;
}
.pre{
    white-space: pre-wrap;
    word-break: break-word;
}
.empty_data{
    text-align: center;
    width: 100%;
    padding: 150px 0;
    color: #888;
}
</style>